import React from "react"
import Video from "../components/video"
import { Link } from "gatsby"

import Layout from "../components/layout"
import LinkExternal from "../components/link_external"
import Seo from "../components/seo"
import CommentSection from "../components/comment_section"
import Routes from "../routes"
import { useImpression } from "../analytics/utils"

const renderParticipants = (participants, videoSource) => (
  <div className="d-flex align-center" style={{ marginBottom: 10 }}>
    {participants.map(({ name, source }, index) => (
      <div className="d-flex align-center" key={`${name}-${videoSource}`}>
        <h3
          style={{
            fontSize: "95%",
            fontWeight: "300",
            marginBottom: 0,
            textAlign: "center",
          }}
        >
          {Routes[source] ? (
            <Link to={source}>{name}</Link>
          ) : (
            <LinkExternal to={source}>{name}</LinkExternal>
          )}
        </h3>
        {index !== participants.length - 1 && (
          <span className="padding-left-10px padding-right-10px">&mdash;</span>
        )}
      </div>
    ))}
  </div>
)

const Debates = ({ location }) => {
  useImpression("Debates")
  return (
    <Layout location={location}>
      <Seo
        title="Best Religious Debates and Discussions of All Time"
        description="Watch the best debates and discussions about religion, atheism, agnosticism. Watch some of the world's greatest minds explore some of the world's most complex topics."
      />
      <h1>The best religious debates and discussions of all time</h1>

      <h3>What are some of the world's best debates about religion?</h3>
      <p>
        Here's a compilation of some of the best religious debates and
        conversions of all time. Dawkins, Dillahunty, Hitchens, Harris, and
        more.
      </p>
      <p>
        Religious debates have been around for thousands of years. People want
        to know the answers to life's most complex and confusing questions.
        We've tried to collect a few of the best debates we've ever seen.
      </p>
      <p>
        Many debates include some of our favorite figures: authors, atheists,
        agnostics, and scientists. And we've have written about a bunch of them.
        Learn more about{" "}
        <Link to="/people/christopher-hitchens">Christopher Hitchens</Link>,{" "}
        <Link to="/people/richard-dawkins">Richard Dawkins</Link>, and{" "}
        <Link to="/people/sam-harris">Sam Harris</Link>.
      </p>
      <p>
        New debates are added all the time, so be sure to check back later for
        more.
      </p>
      {[
        {
          title: "Q and A Debate",
          participants: [
            {
              name: "Richard Dawkins",
              source: Routes.DAWKINS,
            },
            {
              name: "George Pell",
              source: Routes.GEORGE_PELL,
            },
          ],
          url: "https://www.youtube.com/watch?v=tD1QHO_AVZA",
          context: (
            <>
              Enjoy watching Richard Dawkins take down the now convicted child
              sex offender George Pell in this atheist vs. Christian debate.
              This will always be remembered, as George Pell essentially
              confesses to his crimes before ever having been charged (skip to
              48:05), and ultimately digging his own grave when discussing
              preparing the "young English boys" in the middle of this debate.
            </>
          ),
        },
        {
          title: "The God Debate II at The University of Notre Dame",
          participants: [
            {
              name: "Sam Harris",
              source: Routes.SAM_HARRIS,
            },
            {
              name: "William Lane Craig",
            },
          ],
          url: "https://www.youtube.com/watch?v=yqaHXKLRKzg",
        },
        {
          title: "We Don't Do God? - The Veritas Forum",
          participants: [
            {
              name: "Christopher Hitchens",
              source: Routes.HITCHENS,
            },
            {
              name: "John Haldane",
            },
          ],
          url: "https://www.youtube.com/watch?v=pflU-nnY4MA",
        },
        // Video was removed
        // {
        //   title: "",
        //   participants: [
        //     {
        //       name: "Sam Harris",
        //       source: Routes.SAM_HARRIS,
        //     },
        //     {
        //       name: "William Lane Craig",
        //       source: "https://en.wikipedia.org/wiki/William_Lane_Craig",
        //     },
        //   ],
        //   url: "https://www.youtube.com/watch?v=vg7p1BjP2dA",
        // },
        {
          title: "Does God Exist?",
          participants: [
            {
              name: "Christopher Hitchens",
              source: Routes.HITCHENS,
            },
            {
              name: "William Lane Craig",
              source: "https://en.wikipedia.org/wiki/William_Lane_Craig",
            },
          ],
          url: "https://www.youtube.com/watch?v=0tYm41hb48o",
        },
        {
          title: "",
          participants: [
            {
              name: "Richard Dawkins",
              source: Routes.DAWKINS,
            },
          ],
          url: "https://www.youtube.com/watch?v=Bb5uuMskioo",
        },
        {
          title: "The Great God Debate",
          participants: [
            {
              name: "Christopher Hitchens",
              source: Routes.HITCHENS,
            },
            {
              name: "Rabbi David Wolpe",
              source: "https://en.wikipedia.org/wiki/David_Wolpe",
            },
          ],
          url: "https://www.youtube.com/watch?v=2kZRAOXEFPI",
        },
        {
          title: "86 year old atheist scientist versus Christian",
          participants: [{ name: "Atheist/Scientist" }, { name: "Christian" }],
          url: "https://www.youtube.com/watch?v=l8eWSul_l1g",
        },
        {
          title: "Refining Reason Debate",
          participants: [
            {
              name: "Matt Dillahunty",
              source: "https://en.wikipedia.org/wiki/Matt_Dillahunty",
            },
            {
              name: "Sye Ten Bruggencate",
              source: "https://rationalwiki.org/wiki/Sye_Ten_Bruggencate",
            },
          ],
          url: "https://www.youtube.com/watch?v=OL8LREmbDi0",
        },
        {
          title: "Christopher Hitchens Hitchslaps Religion",
          participants: [],
          url: "https://www.youtube.com/watch?v=-pmArHBW9ns",
        },
        {
          title:
            "Neil deGrasse Tyson with Neil Gaiman on Religion and Science, or God of the gaps",
          participants: [],
          url: "https://www.youtube.com/watch?v=4IzHxftS8MI",
        },
      ].map(({ context, title, participants, url }) => (
        <div
          key={url}
          style={{
            padding: 20,
            borderRadius: 8,
            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            transition: "0.3s",
            margin: "30px 0",
          }}
        >
          {title && (
            <div className="align-center flex-space-between">
              <h2 className="padding-top-10px" style={{ margin: 0 }}>
                {title}
              </h2>
              <div>{renderParticipants(participants, url)}</div>
            </div>
          )}

          <Video controls url={url} style={{ boarderRadius: 0.5 }} />
          <p>{context}</p>
        </div>
      ))}

      <p>
        Humans have been believing in gods for tens of thousands of years. We've
        created over 2,500 different gods, granted many of them share quite a
        lot things. Most people, at least in the United States, only believe in
        1 of those gods. That would naturally imply feeling "atheist" in regards
        to at least 2,499 other gods. And an "atheist" simply feels that way
        about all 2,500+ of them..
      </p>

      <p>
        For so much of human history we've clustered ourselves into religious
        groups, choosing the one that <i>feels</i> right to us. But like it or
        not, atheism is on the rise... and rightfully so. Whether you want to
        call it that, or agnosticism, it's safe to say that a lot of people are
        giving up on God. In fact, a{" "}
        <LinkExternal to="https://www.pewforum.org/2019/10/17/in-u-s-decline-of-christianity-continues-at-rapid-pace/">
          Pew Research study
        </LinkExternal>{" "}
        found people unaffiliated with any religion has risen 9% since 2009.
        People are losing interest in the fables of holy books, and instead
        choosing to prioritize things like fighting climate change, balancing
        work and life demands, daily learning and growing, and equality and
        justice for all people. Not to mention, it seems like every other day we
        hear about another scandal in the religious community. The general
        public is finally coming to the truth. Many of us have discovered that
        we were mislead, but now we can begin to take real control over our
        lives, actions, and futures. We no longer have to live under the threat
        of going to heaven or hell. Nor do we have to spend out precious lives
        worshiping someone who has never presented even a shred of evidence for
        his existence. Albeit, atheism will only rise in popularity with the
        expansion of global internet and other technological advancements.
        Religion surely must fade away into a thing of the past; a time when we
        latched on to answers without sufficient evidence.
      </p>

      <p>
        Listen to George Carlin explain the invisible man. He who threatens you
        with eternal torture and damnation! ...But he loves you.
      </p>
      <div className="flex-center">
        <Video controls url="https://youtu.be/gPOfurmrjxo" />
      </div>

      <p>
        Sure enough, another{" "}
        <LinkExternal to="https://www.pewforum.org/2015/11/03/chapter-1-importance-of-religion-and-religious-beliefs/">
          Pew Research Center study from 2014
        </LinkExternal>{" "}
        confirmed that Americans are becoming less religious. Religious
        organizations, and their founders are just losing interest.
      </p>
      <h2>Belief in God &mdash; The United States</h2>
      <p>
        Back in 2007, more than 70% of Americans were confident that God exists.
        Let that sink in... Yet, by 2014 the number has plummeted nearly ten
        percent. But let's get real, these numbers are still absurdly high.
        We're talking about a majority of Americans confident in something that
        that nobody has ever been able to prove. Can you think of another thing
        in society that so many people believe to be true, irrespective of the
        lacking evidence? There have been thousands of discussions about the
        proof for the existence of God, and nobody has made a case that
        fundamentally differs from the requirement of faith based belief.
        <br />
        <br />
        Nearly 1 in 10 Americans say they do not believe in God. In 2007 the
        number was half that. So things are trending in the right direction, but
        it needs to happen faster, exponentially faster.
        <br />
        <br />
        But why do we believe? Are we on a path towards a more skeptical
        society?
      </p>
      <p>
        Clearly we came about somehow, so it's logical to ask why we are here.
        For much of our history, people have dedicated their lives seeking to
        answer this question, and still no one has been able to definitively
        prove it. But we're hungry to know about the origins of the universe. We
        want to know what happens after death, and what happened in the
        beginning.
      </p>
      <p>
        Religion attempts to provide some answers to our questions about life.
        It's not hard to guess an indiviual's religion based on where they were
        born and what socio-economic status they came from. For examlpe, if you
        happened to be raised in the Middle East, you're likely be a Muslim. If
        you were born in Brazil, your would likely be Roman Catholic.
        Ironically, each religious group is equally confident in their beliefs
        and the falsehood of the others. But these religions try to offer
        answers at the expense of science and analysis. The Quran famously
        states that Muhammad flew to heaven on a winged horse. Jesus was said to
        have walked on water, and risen from the dead. Claims as significant as
        extraordinary as these should require extraordinary evidence to support
        them. Yet no religions are able to truly prove their correctness. This
        is clearly demonstrated by the overall decline of organized religion in
        the Wester world.
      </p>
      <CommentSection />
    </Layout>
  )
}

export default Debates
